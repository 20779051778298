import React from 'react';
import Header from './UI/Header';
import Menu from './UI/Menu';
import DashboardUI from './UI/DashboardUI';

function Dashboard() {
  
  return (
    <div>
      <Header />
      <Menu />
      <div style={{
        padding: "10px 50px 20px 50px",
        background: "linear-gradient(45deg, #292E33, #292E33)",
      }} className="content-wrapper">
        <DashboardUI />
      </div>
    </div>
  );
}

export default Dashboard;
