import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Loading from './Loading';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState(null);
  const [displayLoading, setDisplayLoading] = useState(false);

  const navigate = useNavigate()
  const handleLogin = async () => {
    setDisplayLoading(true)
    try {
      if (email.length > 1 && password.length > 1) {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/admin/auth/login', { email, password });
        const token = response.data.data.accessToken;
        localStorage.setItem('token', token);
        localStorage.setItem('name', response.data.data.user.name);
        setDisplayLoading(false)
        navigate('/dashboard/users')
      }
      else {
        setDisplayLoading(false)
        setError("Required Email and password")
      }

    } catch (error) {
      setDisplayLoading(false)
      setError("Wrong Email or password")
      console.error('Login failed:', error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#393C40',
        fontFamily: 'monospace'

      }}
    >
      {displayLoading && <Loading />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '200px',
          padding: '50px'
        }}>
        <h1
          style={{
            fontSize: '2rem',
            marginBottom: '20px',
            color: '#fff',
          }}
        >
          Login
        </h1>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={{
            width: '300px',
            padding: '10px',
            margin: '10px 0',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '1rem',
          }}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{
            width: '300px',
            padding: '10px',
            margin: '10px 0',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '1rem',
          }}
        />
        {error && <p
          style={{
            color: 'red',
            fontSize: '1rem'
          }}>
          {error}
        </p>
        }
        <button
          onClick={handleLogin}
          style={{
            width: '320px',
            padding: '10px',
            marginTop: '30px',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#222426',
            color: '#fff',
            fontSize: '1rem',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#161819'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#222426'}
        >
          Login
        </button>
      </div>

    </div>

  );
}

export default Login;
