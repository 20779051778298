import React, { useState, useEffect } from 'react';

const BasicModal = ({ isOpen, onClose, rowData }) => {
    const [data, setData] = useState(rowData || {});

    useEffect(() => {
        setData(rowData || {});
    }, [rowData]);


    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '50%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    position: 'relative',
                    color: 'black'
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: 'transparent',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    ×
                </button>
                <h2>Details</h2>
                <div>
                    {Object.entries(data).length === 0 ? (
                        null
                    ) : (
                        Object.entries(data).map(([key, value]) => (
                            <div
                                key={key}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    fontFamily: 'Arial, sans-serif'
                                }}
                            >
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        width: '150px',
                                        marginRight: '10px'
                                    }}
                                >
                                    {key}:
                                </label>
                                {(value)}
                            </div>
                        ))
                    )}
                </div>

            </div>
        </div>
    );
};

export default BasicModal;
