import { Route, Routes } from "react-router-dom";
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import App from "./App";

export const DeclareRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path='*' element={<Dashboard />} />
                <Route path='/' element={<App />} />
                <Route path='/login' element={<Login />} />
                <Route path='/dashboard' element={<Dashboard />} />
            </Routes>
        </div>
    );
}
