const NotFound = () => {
    return (
        <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <h1>404 - Page Not Found</h1>
        </div>
    );
};

export default NotFound;