import React, { useMemo } from 'react';
import { verifyUser } from '../../services/auth';
import { useQuery } from "@tanstack/react-query";
import Loading from '../Loading';
import { useNavigate } from 'react-router-dom';
import { getUserTable } from '../../services/data';
import { ColumnFilter } from '../Filters';
import UserTable from '../Table/UserTable';


export default function UserDashboard() {
  const navigate = useNavigate();

  const {
    data: additionalData,
    status: additionalDataStatus,
    isLoading: additionalDataLoading,
    error: additionalDataError,
  } = useQuery({
    queryKey: ['fetchTable'],
    queryFn: () => getUserTable(),
  });

  const columns = useMemo(
    () => [
      {
        Header: 'User_ID',
        accessor: 'user_id',
        Filter: ColumnFilter
      },
      {
        Header: 'Name',
        accessor: 'name',
        Filter: ColumnFilter
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: ColumnFilter
      },
      {
        Header: 'Subscription ID',
        accessor: 'subscription_id',
        Filter: ColumnFilter
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: ColumnFilter
      },
      {
        Header: 'User Type',
        accessor: 'user_type',
        Filter: ColumnFilter
      },
      {
        Header: 'Provider',
        accessor: 'provider',
        Filter: ColumnFilter
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Filter: ColumnFilter
      },
      {
        Header: 'Subscription Expiry',
        accessor: 'subscription_expiry',
        Filter: ColumnFilter
      },
      {
        Header: 'Next Billing',
        accessor: 'next_billing',
        Filter: ColumnFilter
      },
      {
        Header: 'Gateway',
        accessor: 'gateway',
        Filter: ColumnFilter
      },
      {
        Header: 'Country Code',
        accessor: 'country_code',
        Filter: ColumnFilter
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile_number',
        Filter: ColumnFilter
      },
      {
        Header: 'Username',
        accessor: 'username',
        Filter: ColumnFilter
      },
      {
        Header: 'Plan Name',
        accessor: 'plan_name',
        Filter: ColumnFilter
      },
      {
        Header: 'Plan Frequency',
        accessor: 'plan_frequency',
        Filter: ColumnFilter
      },
      {
        Header: 'Plan Location',
        accessor: 'plan_location',
        Filter: ColumnFilter
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Filter: ColumnFilter
      },
      {
        Header: 'Lifetime Value',
        accessor: 'lifetime_value',
        Filter: ColumnFilter
      }
    ],
    []
  );

  if (additionalDataLoading) return <Loading />
  if (additionalDataError) {
    navigate('/login')
  }

  return (
    <div>
      {additionalDataError && (<h1>Failed to fetch user table</h1>)}
      {(!additionalDataLoading && additionalDataStatus === 'success') && <UserTable tableData={additionalData?.data.data} columns={columns} />}
    </div>

  )
}
