import React, { useEffect, useState } from 'react'
import { getRecoveryRecordsInfo, getUsernameFromToken } from '../../services/data';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export default function Menu() {

  const name = localStorage.getItem('name')
  const [openMenus, setOpenMenus] = useState({
    parent1: false,
    parent2: false,
    parent3: false,
  });

  const [archived, setArchived] = useState('')
  const [open, setOpen] = useState('')

  const { data, error } = useQuery({
    queryKey: ['getRecordNumbers'],
    queryFn: getRecoveryRecordsInfo,
    onError: (error) => {
      console.error('Failed to fetch recovery info');
    },
    retry: false
  });

  useEffect(() => {
    if (data) {
      setOpen(data.data.data.open ? data.data.data.open : 0)
      setArchived(data.data.data.closed ? data.data.data.closed : 0)
    }
  }, [data]);


  const toggleMenu = (menuName) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4"
        style={{
          position: 'fixed',
        }}
      >
        {/* Brand Logo */}
        <Link to="/dashboard/users" className="brand-link">
          <img src="/logo.png" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
          <span className="brand-text font-weight-light">Prachyam</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">
              <a href="#" className="d-block">{name}</a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column">
              <li className="nav-item has-treeview menu-open">
                <a role="button" className="nav-link active" onClick={() => toggleMenu("parent1")}>
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Dashboards
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                {openMenus.parent1 && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="dashboard/users" role="button" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Users</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dashboard/issue" role="button" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Issues</p>
                      </Link>
                    </li>
                  </ul>)}
              </li>

              <li className="nav-item has-treeview menu-open">
                <a role="button" className="nav-link" onClick={() => toggleMenu("parent2")}>
                  <i className="nav-icon fas fa-chart-pie" />
                  <p>
                    Recovery
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                {openMenus.parent2 && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="dashboard/recovery/open" role="button" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Open ({open})</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dashboard/recovery/archive" role="button" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Archived ({archived})</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dashboard/retention" role="button" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Retention</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="dashboard/failures" role="button" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Payment fail</p>
                      </Link>
                    </li>
                  </ul>)}
              </li>

              <li className="nav-item has-treeview menu-open">
                <a role="button" className="nav-link" onClick={() => toggleMenu("parent3")}>
                  <i className="nav-icon fas fa-chart-pie" />
                  <p>
                    Charts
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                {openMenus.parent3 && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Revenue</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Flot</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Inline</p>
                      </a>
                    </li>
                  </ul>)}
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  )
}
