import React from 'react';
import '../styles/loading.css'; 

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <img src="/loading.gif" alt="loading...." />
      </div>
    </div>
  );
}

export default Loading;
