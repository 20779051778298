import React, { useMemo } from 'react';
import { useQuery } from "@tanstack/react-query";
import Loading from '../Loading';
import { useNavigate } from 'react-router-dom';
import { getRetentionTable } from '../../services/data';
import { ColumnFilter } from '../Filters';
import RetentionTable from '../Table/RetentionTable';

export default function RetentionDashboard() {
    const navigate = useNavigate();

    const {
        data: additionalData,
        status: additionalDataStatus,
        isLoading: additionalDataLoading,
        error: additionalDataError,
    } = useQuery({
        queryKey: ['fetchRetentionTable'],
        queryFn: () => getRetentionTable(),
    });

    const columns = useMemo(
        () => [
            {
                Header: 'Strapi_ID',
                accessor: 'userStrapiId',
                Filter: ColumnFilter
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                Filter: ColumnFilter,
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleString('en-IN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }).replace(',', '');
                }
            },
            {
                Header: 'Updated At',
                accessor: 'updatedAt',
                Filter: ColumnFilter,
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleString('en-IN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }).replace(',', '');
                }
            },
            {
                Header: 'Type',
                accessor: 'type',
                Filter: ColumnFilter
            },
            {
                Header: 'Status',
                accessor: 'status',
                Filter: ColumnFilter
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                Filter: ColumnFilter
            },
            {
                Header: 'Country Code',
                accessor: 'countryCode',
                Filter: ColumnFilter
            },
            {
                Header: 'Email',
                accessor: 'email',
                Filter: ColumnFilter
            },
            {
                Header: 'Stage',
                accessor: 'stage',
                Filter: ColumnFilter
            },
            {
                Header: 'Gateway',
                accessor: 'gateway',
                Filter: ColumnFilter
            },
            {
                Header: 'Invoice ID',
                accessor: 'invoiceId',
                Filter: ColumnFilter
            },
            {
                Header: 'Error',
                accessor: 'error',
                Filter: ColumnFilter
            },
            {
                Header: 'Error Description',
                accessor: 'errorDescription',
                Filter: ColumnFilter
            }
        ],
        []
    );

    if (additionalDataLoading) return <Loading />
    if (additionalDataError) {
        navigate('/login')
    }

    return (
        <div>
            {additionalDataError && (<h1>Failed to fetch user table</h1>)}
            {(!additionalDataLoading && additionalDataStatus === 'success') && <RetentionTable tableData={additionalData?.data.data.data} columns={columns} />}
        </div>

    )
}
