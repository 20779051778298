import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { archiveRecoveryRecord, cancelSubscription, deleteUser, fetchChatData, sendChatMessage } from '../../services/data';

const RecoveryModal = ({ isOpen, onClose, rowData }) => {
    const [data, setData] = useState(rowData || {});

    const mutation = useMutation({
        mutationFn: archiveRecoveryRecord,
        onSuccess: (data) => {
            console.log('Record updated successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            alert("Error updating")
        }
    });

    const cancelMutation = useMutation({
        mutationFn: cancelSubscription,
        onSuccess: (data) => {
            console.log('Record updated successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            alert("Error updating")
        }
    });

    const deleteMutation = useMutation({
        mutationFn: deleteUser,
        onSuccess: (data) => {
            console.log('Record updated successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            alert("Error updating")
        }
    });

    // const deleteMutation = useMutation({
    //     mutationFn: deleteIssueRecord,
    //     onSuccess: (data) => {
    //         console.log('Record deleted successfully:', data);
    //         onClose();
    //         window.location.reload();
    //     },
    //     onError: (error) => {
    //         console.error('Failed to delete record:', error);
    //         alert("Error delete")
    //     }
    // });

    useEffect(() => {
        setData(rowData || {});
    }, [rowData]);



    const handleInputChange = (key, newValue) => {
        setData(prevData => ({
            ...prevData,
            [key]: newValue
        }));
    };

    const handleUpdateClick = () => {
        mutation.mutate(data);
    };

    const handleCancelClick = () => {
        cancelMutation.mutate(data)
    }

    const handleDeleteClick = () => {
        deleteMutation.mutate(data)
    }

    const [messages, setMessages] = useState([]);

    const [inputValue, setInputValue] = useState('');

    const sendMessage = async (e) => {
        e.preventDefault();
        if (inputValue.trim()) {
            if (inputValue.length < 3) {
                alert('Input must be at least 3 characters long');
            } else {
                try {
                    await sendChatMessage(data.id, inputValue)
                } catch (error) {
                    alert(error)
                }
                await loadChatData();
                setInputValue('');
            }
        }
    };
    const loadChatData = async () => {
        try {
            const result = await fetchChatData(data.id);
            console.log(result.data)
            setMessages(result.data.data)
        } catch (error) {
            alert(error.message);
        }
    };

    useEffect(() => {
        if (data.id)
            loadChatData();
    }, [data]);

    const [showChat, setShowChat] = useState(false);


    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '50%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    position: 'relative',
                    color: 'black'
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: 'transparent',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    ×
                </button>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    width: '100%',
                }}>


                    <div style={{ flex: 1 }}>
                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Request ID:
                            </label>
                            <p>
                                {data.id}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Category:
                            </label>
                            <p>
                                {data.type}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Name:
                            </label>
                            <p>
                                {data.name}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Email:
                            </label>
                            <p>
                                {data.email}
                            </p>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                user_id:
                            </label>
                            <p>
                                {data.strapiId}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Is Paid User:
                            </label>
                            <p>
                                {data.isPaidUser ? 'true' : 'false'}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Country Code:
                            </label>
                            <p>
                                {data.countryCode}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Base Number:
                            </label>
                            <p>
                                {data.phone}
                            </p>
                        </div>

                        {data.type == "DELETE_ACCOUNT" &&
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Deletion Reason:
                                    </label>
                                    <p>
                                        {data.deletionReason}
                                    </p>
                                </div>
                            </div>
                        }

                        {data.type == "CANCEL_SUBSCRIPTION" &&
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Cancel Reason:
                                    </label>
                                    <p>
                                        {data.cancellationReason}
                                    </p>
                                </div>
                            </div>
                        }

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Additional Comments:
                            </label>
                            <p>
                                {data.additionalComments}
                            </p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Archived:
                            </label>
                            <p>
                                {data.archived ? "True" : "False"}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Closure Status
                            </label>

                            <select id="options" value={data.status}
                                onChange={(e) => handleInputChange("status", e.target.value)}
                                style={{
                                    padding: "5px",
                                    fontSize: "16px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    backgroundColor: "#f9f9f9",
                                    color: "black",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                            >
                                <option value="PENDING">PENDING</option>
                                <option value="SELF_RECOVERED">SELF_RECOVERED</option>
                                <option value="RECOVERED">RECOVERED</option>
                                <option value="LOST">LOST</option>
                            </select>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Created At:
                            </label>
                            <p>
                                {new Date(data.createdAt).toLocaleString('en-IN', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                }).replace(',', '')}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                }}
                            >
                                Updated At:
                            </label>
                            <p>
                                {new Date(data.updatedAt).toLocaleString('en-IN', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                }).replace(',', '')}
                            </p>
                        </div>

                        <div style={{ height: '10px;' }}>
                            {showChat ? <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowChat(false) }}>hide chat</a> :
                                <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowChat(true) }}>show chat</a>}
                        </div>

                    </div>

                    {showChat ?
                        <div style={{ flex: 1, color: 'black' }}>
                            <div style={{
                                width: '400px',
                                height: '500px',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '10px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            }}>
                                <div style={{
                                    flex: 1,
                                    overflowY: 'auto',
                                    marginBottom: '5px',
                                }}>
                                    {messages.map((message, index) => (
                                        <div key={index} style={{ marginBottom: '1px' }}>
                                            {message.admin.name}: {message.message}
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                                <form onSubmit={sendMessage} style={{
                                    display: 'flex',
                                }}>
                                    <input
                                        type="text"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        placeholder='message'
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            marginRight: '10px',
                                        }}
                                    />
                                    <button type="submit" style={{
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        backgroundColor: '#43C685',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }}>
                                        Send ➤
                                    </button>
                                </form>
                            </div>
                        </div>
                        : null}
                </div>

                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                    {data.type == "DELETE_ACCOUNT" &&
                        <button
                            style={{
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '4px',
                                backgroundColor: 'red',
                                color: 'white',
                                fontSize: '16px',
                                cursor: 'pointer',
                                marginRight: '10px'
                            }}
                            onClick={handleDeleteClick}
                        >
                            Delete User & Archieve
                        </button>
                    }

                    {data.type == "CANCEL_SUBSCRIPTION" &&
                        <button
                            style={{
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '4px',
                                backgroundColor: 'red',
                                color: 'white',
                                fontSize: '16px',
                                cursor: 'pointer',
                                marginRight: '10px'
                            }}
                            onClick={handleCancelClick}
                        >
                            Cancel subscription & Archieve
                        </button>
                    }

                    <button
                        style={{
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '4px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            fontSize: '16px',
                            cursor: 'pointer',
                        }}
                        onClick={handleUpdateClick}
                    >
                        Archieve
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RecoveryModal;
