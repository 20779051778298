import axios from "axios";
import { jwtDecode } from 'jwt-decode';

export const getUserTable = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/v1/strapi/find-users?pageNo=1&pageSize=10000000', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.error("Error in getUserTable:" + error)
    }
}


export const getCustomTable = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/custom-table', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.log("Error in getUserTable:" + error)
    }
}

export const getUsernameFromToken = () => {
    try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        return decodedToken.firstname;
    } catch (error) {
        console.error('Invalid token:', error);
        return null;
    }
};

export const sendUserRecord = async (data) => {
    try {
        const response = await axios.put(process.env.REACT_APP_API_URL + '/api/v1/strapi/update-user', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        return response;
    } catch (error) {
        console.log("Error in sendUserRecord:" + error)
        throw error;
    }
}

// export const getIssueTable = async () => {
//     try {
//         const response = await axios.get(process.env.REACT_APP_API_URL + '/api/issue-table', {
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('token')}`
//             }
//         })
//         return response;
//     } catch (error) {
//         console.log("Error in getIssueTable:" + error)
//     }
// }


// export const sendIssueRecord = async (data) => {
//     try {
//         const response = await axios.post(process.env.REACT_APP_API_URL + '/api/create-issue', data, {
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('token')}`
//             },
//         })
//         return response;
//     } catch (error) {
//         console.log("Error in sendIssueRecord:" + error)
//         throw error;
//     }
// }

// export const updateIssueRecord = async (data) => {
//     try {
//         const response = await axios.put(process.env.REACT_APP_API_URL + `/api/update-issue/${data.id}`, { 'status': data.status }, {
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('token')}`
//             },
//         })
//         return response;
//     } catch (error) {
//         console.log("Error in updateIssueRecord:" + error)
//         throw error;
//     }
// }

// export const deleteIssueRecord = async (data) => {
//     try {
//         const response = await axios.delete(process.env.REACT_APP_API_URL + `/api/delete-issue/${data.id}`, {
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('token')}`
//             },
//         })
//         return response;
//     } catch (error) {
//         console.log("Error in updateIssueRecord:" + error)
//         throw error;
//     }
// }

export const getRecoveryTable = async (status) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/admin/user-request?archived=${status}&pageSize=100000`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.error("Error in getRecoveryTable:" + error)
    }
}

export const getRecoveryRecordsInfo = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/admin/user-request/get-count`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.error("Error in getRecoveryTable:" + error)
    }
};

export const archiveRecoveryRecord = async (data) => {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/user-request`, { requestId: data.id, status: data.status, archived: true, },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response;
    } catch (error) {
        console.log("Error in archiveRecoveryRecord:" + error)
        throw error;
    }
}

export const cancelSubscription = async (data) => {
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/action/cancel-subscription/${data.id}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response;
    } catch (error) {
        console.log("Error in cancelSubscription:" + error)
        throw error;
    }
}

export const deleteUser = async (data) => {
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/action/delete-user/${data.id}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response;
    } catch (error) {
        console.log("Error in deleteUser:" + error)
        throw error;
    }
}

export const fetchChatData = async (requestId) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/admin/user-request/get-chats/${requestId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.error("Error in fetchChatData:" + error)
    }
};


export const sendChatMessage = async (requestId, message) => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + `/api/v1/admin/user-request/add-chat`,
            {
                "message": message,
                "requestId": requestId
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
        return response;
    } catch (error) {
        console.error("Error in sendChatMessage:" + error)
    }
};

export const getRetentionTable = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/admin/retention?pageSize=100000`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.error("Error in getRetentionTable:" + error)
    }
}


export const getFirstPaymentFailureTable = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/admin/retention/payment-attempts?pageSize=100000`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (error) {
        console.error("Error in getFirstPaymentFailureTable:" + error)
    }
}

