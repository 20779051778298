import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendUserRecord } from '../../services/data';

const DetailModal = ({ isOpen, onClose, rowData }) => {
    const [data, setData] = useState(rowData || {});

    const mutation = useMutation({
        mutationFn: sendUserRecord,
        onSuccess: (data) => {
            console.log('Record sent successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            alert("Error updating")
        }
    });

    useEffect(() => {
        setData(rowData || {});
    }, [rowData]);

    const handleInputChange = (key, newValue) => {
        setData(prevData => ({
            ...prevData,
            [key]: newValue
        }));
    };

    const handleUpdateClick = () => {
        console.log('Updating with data:', data);
        mutation.mutate(data);
    };

    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '50%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    position: 'relative',
                    color: 'black'
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: 'transparent',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    ×
                </button>
                <h2>Account Details</h2>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        user_id:
                    </label>
                    {data.user_id}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        name:
                    </label>
                    <input
                        type="text"
                        value={data.name || ''}
                        onChange={(e) => handleInputChange("name", e.target.value)}
                        style={{
                            padding: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            flex: 1
                        }}
                    />
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        email:
                    </label>
                    <input
                        type="text"
                        value={data.email || ''}
                        onChange={(e) => handleInputChange("email", e.target.value)}
                        style={{
                            padding: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            flex: 1
                        }}
                    />
                </div>



                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        subscription_id:
                    </label>
                    {data.subscription_id}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        status:
                    </label>
                    {data.status}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        user_type:
                    </label>
                    <div>
                        <select id="options" value={data.user_type}
                            onChange={(e) => handleInputChange("user_type", e.target.value)}
                            style={{
                                padding: "5px",
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                backgroundColor: "#f9f9f9",
                                color: "black",
                                outline: "none",
                                cursor: "pointer",
                            }}
                        >
                            <option value="normal_user">normal_user</option>
                            <option value="special_access">special_access</option>
                        </select>
                    </div>
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        provider:
                    </label>
                    {data.provider}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        created_at:
                    </label>
                    {data.created_at}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        subscription_expiry:
                    </label>
                    {data.subscription_expiry}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        next_billing:
                    </label>
                    {data.next_billing}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        gateway:
                    </label>
                    {data.gateway}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        country_code:
                    </label>
                    <input
                        type="text"
                        value={data.country_code || ''}
                        onChange={(e) => handleInputChange("country_code", e.target.value)}
                        style={{
                            padding: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            flex: 1
                        }}
                    />
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        mobile_number:
                    </label>
                    <input
                        type="text"
                        value={data.mobile_number || ''}
                        onChange={(e) => handleInputChange("mobile_number", e.target.value)}
                        style={{
                            padding: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            flex: 1
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        username:
                    </label>
                    <input
                        type="text"
                        value={data.username || ''}
                        onChange={(e) => handleInputChange("username", e.target.value)}
                        style={{
                            padding: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            flex: 1
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        plan_name:
                    </label>
                    {data.plan_name}
                </div>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        plan_frequency:
                    </label>
                    {data.plan_frequency}
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        plan_location:
                    </label>
                    {data.plan_location}
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        notes:
                    </label>
                    <input
                        type="text"
                        value={data.notes || ''}
                        onChange={(e) => handleInputChange("notes", e.target.value)}
                        style={{
                            padding: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            flex: 1
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        fontFamily: 'Arial, sans-serif'
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            width: '150px',
                            marginRight: '10px'
                        }}
                    >
                        lifetime_value:
                    </label>
                    {data.lifetime_value}
                </div>

                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                    <button
                        style={{
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '4px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            fontSize: '16px',
                            cursor: 'pointer',
                        }}
                        onClick={handleUpdateClick}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DetailModal;
