import React, { useMemo } from 'react';
import { verifyUser } from '../../services/auth';
import { useQuery } from "@tanstack/react-query";
import Loading from '../Loading';
import { useNavigate } from 'react-router-dom';
import { getRecoveryTable, getUserTable } from '../../services/data';
import { ColumnFilter } from '../Filters';
import RecoveryTable from '../Table/RecoveryTable';

export default function RecoveryDashboard() {
  const navigate = useNavigate();


  const {
    data: additionalData,
    status: additionalDataStatus,
    isLoading: additionalDataLoading,
    error: additionalDataError,
  } = useQuery({
    queryKey: ['fetchRecoveryTable'],
    queryFn: () => getRecoveryTable('false'),
  });


  const columns = useMemo(
    () => [
      {
        Header: 'Strapi ID',
        accessor: 'strapiId',
        Filter: ColumnFilter
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Filter: ColumnFilter,
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }).replace(',', '');
        }
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Filter: ColumnFilter,
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }).replace(',', '');
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        Filter: ColumnFilter
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: ColumnFilter
      },
      {
        Header: 'Is Paid User',
        accessor: 'isPaidUser',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        Filter: ColumnFilter
      },
      {
        Header: 'Country Code',
        accessor: 'countryCode',
        Filter: ColumnFilter
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        Filter: ColumnFilter
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: ColumnFilter
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: ColumnFilter
      },
      {
        Header: 'Cancellation Reason',
        accessor: 'cancellationReason',
        Filter: ColumnFilter
      },
      {
        Header: 'Deletion Reason',
        accessor: 'deletionReason',
        Filter: ColumnFilter
      },
      {
        Header: 'Additional Comments',
        accessor: 'additionalComments',
        Filter: ColumnFilter
      },
      {
        Header: 'Action Taken By ID',
        accessor: 'actionTakenById',
        Filter: ColumnFilter
      },
    ],
    []
  );

  if (additionalDataLoading) return <Loading />
  if (additionalDataError) {
    navigate('/login')
  }

  return (
    <div>
      {additionalDataError && (<h1>Failed to fetch user table</h1>)}
      {(!additionalDataLoading && additionalDataStatus === 'success') && <RecoveryTable tableData={additionalData?.data.data.data} columns={columns} />}
    </div>

  )
}
