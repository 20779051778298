import React from "react";

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span style={{ display: 'flex', fontSize: '20px', alignItems: 'center', gap: '10px' }}>
      <label htmlFor="searchInput" style={{ fontWeight: 'bold',color:'white' }}>
        Global Search:
      </label>
      <input
        id="searchInput"
        type="text"
        placeholder="Search"
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        style={{
          padding: '8px 12px',
          border: '1px solid #ddd',
          borderRadius: '4px',
          fontSize: '16px',
          width: '200px',
        }}
      />
    </span>
  )
}


export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <span>
      <input
        placeholder="Search"
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value)}
      ></input>
    </span>
  )
}