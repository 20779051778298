import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand sidebar-dark-primary  navbar-dark">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="/dashboard" className="nav-link">Home</a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link">Contact</a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li role="button" className="nav-item dropdown">
            <a className="nav-link" onClick={handleLogout}>
              <span>Log Out</span>
            </a>
          </li>
        </ul>

      </nav>
    </div>

  )
}
