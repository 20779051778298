import "../../styles/table.css"
import { React, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy, useGlobalFilter, useFilters } from "react-table";
import { GlobalFilter } from "../Filters";
import DetailModal from "../Modal/DetailModal";

function UserTable({ tableData, columns }) {
    const data = useMemo(() => tableData, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 100 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);


    const handleCellClick = (row) => {
        setSelectedRowData(row.original);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedRowData(null);
    };


    const [applyFilter, setApplyFilter] = useState(false)

    const handleFilter = () => {
        setApplyFilter(!applyFilter);
    };


    return (
        <div>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}>
            </GlobalFilter>
            <div className="table-container">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.id !== 'user_type' ? column.getSortByToggleProps() : {}
                                        )}
                                    >
                                        {column.render("Header")}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {applyFilter && (column.canFilter ? column.render('Filter') : null)}
                                        </div>                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps()}
                                            onClick={() => handleCellClick(row)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <DetailModal
                    isOpen={modalOpen}
                    onClose={handleCloseModal}
                    rowData={selectedRowData}
                />
            </div>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>

                <span>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: '100px' }}
                    />
                </span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[100, 200, 400, 500, 1000, 2000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>

                <span style={{ marginLeft: '20px', marginTop: '2px' }}>
                    {applyFilter ? "Hide filters" : "Show filters"}
                </span>
                <div style={{ marginLeft: '10px', marginRight: '10px', marginTop: '2px' }}>
                    <input
                        type="checkbox"
                        checked={applyFilter}
                        onChange={handleFilter}
                        style={{ marginLeft: '10px' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default UserTable;
