import React from 'react';
import UserDashboard from '../Dashboard/UserDashboard';
import { Route, Routes } from 'react-router-dom';
import IssueDashboard from '../Dashboard/IssueDashboard';
import RecoveryDashboard from '../Dashboard/RecoveryDashboard';
import RecoveryDashboardArch from '../Dashboard/RecoveryDashboardArch';
import RetentionDashboard from '../Dashboard/RetentionDashboard';
import PaymentDashboard from '../Dashboard/PaymentDashboard';
import NotFound from '../NotFound';

export default function DashboardUI() {

  return (

    <div>
      <Routes>
        <Route path='dashboard/users' element={<UserDashboard />} />
        {/* <Route path='dashboard/issue' element={<IssueDashboard />} /> */}
        <Route path='dashboard/issue' element={<ComingSoon />} />
        <Route path='dashboard/customer' element={<ComingSoon />} />
        <Route path='dashboard/recovery/open' element={<RecoveryDashboard />} />
        <Route path='dashboard/recovery/archive' element={<RecoveryDashboardArch />} />
        <Route path='dashboard/retention' element={<RetentionDashboard />} />
        <Route path='dashboard/failures' element={<PaymentDashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>

  )
}

const ComingSoon = () => {
  return (
    <div>
      <img src="/loading.gif" alt="loading...." />
      In progress....
    </div>
  )
}
