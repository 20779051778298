import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { archiveRecoveryRecord, cancelSubscription, deleteUser, fetchChatData, sendChatMessage } from '../../services/data';

const RecoveryModalArch = ({ isOpen, onClose, rowData }) => {
    const [data, setData] = useState(rowData || {});

    useEffect(() => {
        setData(rowData || {});
    }, [rowData]);


    const [messages, setMessages] = useState([]);

    const [inputValue, setInputValue] = useState('');

    const loadChatData = async () => {
        try {
            const result = await fetchChatData(data.id);
            console.log(result.data)
            setMessages(result.data.data)
        } catch (error) {
            alert(error.message);
        }
    };

    useEffect(() => {
        if (data.id)
            loadChatData();
    }, [data]);

    const [showChat, setShowChat] = useState(false);


    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '50%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    position: 'relative',
                    color: 'black'
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: 'transparent',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    ×
                </button>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    width: '100%',
                }}>

                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div style={{ flex: 1 }}>
                                <div style={{ display: 'flex' }}>

                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Closure Status:
                                    </label>
                                    <p>
                                        {data.status}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Request ID:
                                    </label>
                                    <p>
                                        {data.id}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Category:
                                    </label>
                                    <p>
                                        {data.type}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Name:
                                    </label>
                                    <p>
                                        {data.name}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Email:
                                    </label>
                                    <p>
                                        {data.email}
                                    </p>
                                </div>


                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        user_id:
                                    </label>
                                    <p>
                                        {data.strapiId}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Is Paid User:
                                    </label>
                                    <p>
                                        {data.isPaidUser ? 'true' : 'false'}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Country Code:
                                    </label>
                                    <p>
                                        {data.countryCode}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Base Number:
                                    </label>
                                    <p>
                                        {data.phone}
                                    </p>
                                </div>

                                {data.type == "DELETE_ACCOUNT" &&
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <label
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Deletion Reason:
                                            </label>
                                            <p>
                                                {data.deletionReason}
                                            </p>
                                        </div>
                                    </div>
                                }

                                {data.type == "CANCEL_SUBSCRIPTION" &&
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <label
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Cancel Reason:
                                            </label>
                                            <p>
                                                {data.cancellationReason}
                                            </p>
                                        </div>
                                    </div>
                                }

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Additional Comments:
                                    </label>
                                    <p>
                                        {data.additionalComments}
                                    </p>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Archived:
                                    </label>
                                    <p>
                                        {data.archived ? "True" : "False"}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Created At:
                                    </label>
                                    <p>
                                        {new Date(data.createdAt).toLocaleString('en-IN', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }).replace(',', '')}
                                    </p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Updated At:
                                    </label>
                                    <p>
                                        {new Date(data.updatedAt).toLocaleString('en-IN', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }).replace(',', '')}
                                    </p>
                                </div>

                                <div style={{ height: '10px;' }}>
                                    {showChat ? <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowChat(false) }}>hide chat</a> :
                                        <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowChat(true) }}>show chat</a>}
                                </div>

                            </div>

                            {showChat ?
                                <div style={{ flex: 1, color: 'black' }}>
                                    <div style={{
                                        width: '400px',
                                        height: '500px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '10px',
                                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                    }}>
                                        <div style={{
                                            flex: 1,
                                            overflowY: 'auto',
                                            marginBottom: '5px',
                                        }}>
                                            {messages.map((message, index) => (
                                                <div key={index} style={{ marginBottom: '1px' }}>
                                                    {message.message}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecoveryModalArch;
